import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

const Box = ({ title, logo, description, className, logoClassName, ...props }) => {
  const transition = { duration: 1, ease: [0.25, 0.1, 0.25, 1] };
  const variants = {
    hidden: { opacity: 0, y: 100, filter: "blur(20px)" },
    visible: { opacity: 1, y: 0, filter: "blur(0)" },
  };

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.6 }}
      className={`w-[325.33px] bg-[#F6F5F4] rounded-[12px] flex flex-auto pt-9 pb-7 px-8 text-left ${className}`}
      {...props}
    >
      <div className="box-header">
        <motion.img
          variants={variants}
          transition={transition}
          className={`box-logo w-[39px] ${logoClassName}`}
          src={logo}
          alt="logo"
        />
        <motion.h3
          variants={variants}
          transition={{ ...transition, delay: 0.2 }}
          className="box-title mt-1 font-[900] text-lg"
        >
          {title}
        </motion.h3>
        <motion.p
          variants={variants}
          transition={{ ...transition, delay: 0.4 }}
          className="box-description mt-1 text-[#121212]"
        >
          {description}
        </motion.p>
      </div>
    </motion.div>
  );
};

export default Box;
