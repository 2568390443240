import "../styles/variables.css";
import Button from "./Button";
import logo from "../assets/logo.svg";

export default function Navbar() {
  return (
    <nav className="bg-[#fff] text-[#1a1a1a] py-4 border-b-[1px] border-[#e5e5e5]">
      <div className="container mx-auto px-4 flex justify-between items-center">
        <img src={logo} alt="logo" />
        <Button text="Discutons ->" className="text-[15px]" />
      </div>
    </nav>
  );
}