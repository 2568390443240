import * as React from "react";
import "../styles/variables.css";

function Button({ text = "Discutons", onClick, className, ...props }) {
  return (
    <button
      className={`bg-[var(--accent-color)] text-[var(--background-color)] font-bold py-[8px] px-[15px] rounded-[5.75px] 
      shadow-md hover:bg-[#30595b] hover:scale-105 transition-all duration-300 ease-in-out whitespace-nowrap ${className}`}
      onClick={onClick}
      {...props}
    >
      {text}
    </button>
  );
}

export default Button;