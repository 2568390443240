import logo from "./assets/logo.svg";
import magnifyingGlass from "./assets/magnifyingglass.png";
import Chart from "./assets/chart.line.uptrend.xyaxis.png";
import Rectangle from "./assets/rectangle.3.group.png";
import "./App.css";
import BlurReveal from "./components/BlurReveal";
import Navbar from "./components/Navbar";
import illustration from "./assets/Illustrations.svg";
import Button from "./components/Button";
import Box from "./components/Box";
import { motion } from "framer-motion";
import ProgressSteps from "./components/ProgressSteps";
import Step from "./components/Step";
const transition = { duration: 1, ease: [0.25, 0.1, 0.25, 1] };
const variants = {
  hidden: { filter: "blur(10px)", transform: "translateY(10%)", opacity: 0 },
  visible: { filter: "blur(0)", transform: "translateY(0)", opacity: 1 },
};

function App() {
  return (
    <div className="App max-w-[1440px] mx-auto">
      <motion.div
        initial={{
          filter: "blur(70px)",
          opacity: 0,
        }}
        animate={{
          filter: "blur(0)",
          opacity: 1,
        }}
        transition={{
          ...transition,
          delay: 0.5,
        }}
      >
        <Navbar />
      </motion.div>
      <motion.div
        initial="hidden"
        animate="visible"
        viewport={{ once: true, amount: 0.6 }}
        variants={variants}
        transition={{ ...transition, delay: 0.5 }}
      >
        <img
          src={illustration}
          alt="illustration"
          className="w-full h-auto max-w-[298px] mb-4 mx-auto block"
        />
      </motion.div>
      <BlurReveal />
      <motion.div
        initial={{ opacity: 0, y: 100, filter: "blur(50px)" }}
        animate={{ opacity: 1, y: 0, filter: "blur(0)" }}
        transition={{ ...transition, delay: 0.7 }}
      >
        <Button
          text="Parlez-nous de votre projet ->"
          className="py-[10px] rounded-[6.75px] text-[18px] mt-[22px]"
        />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 100, filter: "blur(50px)" }}
        whileInView={{ opacity: 1, y: 0, filter: "blur(0)" }}
        transition={{ ...transition, delay: 0.2 }}
        viewport={{ once: true, amount: 0.3 }}
      >
        <h2 className="mt-[360px] text-center text-[45px] font-[900] tracking-[-1.56px]">
          Vos bénéfices
        </h2>
      </motion.div>
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={variants}
        transition={transition}
        viewport={{ once: true, amount: 0.2 }}
      >
        <div className="flex justify-center gap-7 px-20 mt-10">
          <Box
            title="Visibilité"
            logo={magnifyingGlass}
            logoClassName="w-[26px]"
            description="Démarquez-vous avec une identité digitale remarquable."
          />
          <Box
            title="Conversion"
            logo={Chart}
            logoClassName="w-[25px]"
            description="Une interface pensée et optimisée pour la conversion."
          />
          <Box
            title="Multi-Platforme"
            logo={Rectangle}
            logoClassName="w-[30px]"
            description="Expérience digitale sur tout vos appareils."
          />
        </div>

        <ProgressSteps />
      </motion.div>
    </div>
  );
}

export default App;
