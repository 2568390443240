"use client";

import React from "react";
import { motion } from "framer-motion";
import "../styles/variables.css";

const transition = { duration: 1, ease: [0.25, 0.1, 0.25, 1] };
const variants = {
  hidden: { filter: "blur(10px)", transform: "translateY(20%)", opacity: 0 },
  visible: { filter: "blur(0)", transform: "translateY(0)", opacity: 1 },
};

const text = "Votre projet,\npoussé à l'excellence.";

export default function BlurReveal() {
  const lines = text.split("\n");

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.6 }}
      transition={{ staggerChildren: 0.04 }}
      className="flex flex-col items-center justify-center align-middle"
    >
      <h1 className="w-[auto] mb-6 leading-[72.7px] text-[74.7px] text-center font-[900] tracking-[-3.56px] text-[var(--text-color)] ">
        {lines.map((line, lineIndex) => (
          <React.Fragment key={lineIndex}>
            {line.split("").map((char, charIndex) => (
              <motion.span
                key={`${lineIndex}-${charIndex}`}
                className="inline-block"
                transition={{transition }}
                variants={variants}
              >
                {char === " " ? "\u00A0" : char}
              </motion.span>
            ))}
            {lineIndex < lines.length - 1 && <br />}
          </React.Fragment>
        ))}
      </h1>
      <motion.p
        className="w-[auto] mb-8 text-[19.09px] font-medium leading-[22px] text-center text-[var(--secondary-color)] whitespace-pre-line"
        transition={{ ...transition, delay: 1 }}
        variants={variants}
      >
        {`Nous propulsons vos projets web à un niveau supérieur grâce à une\nexpertise en développement, design, et psychologie.`}
      </motion.p>
    </motion.div>
  );
}