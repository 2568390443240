// Step.js
import React from "react";
import { motion } from "framer-motion";
import "../styles/variables.css";

const Step = ({ number, title, description, isActive, onClick }) => {
  return (
    <motion.div
      className="flex flex-col max-w-[250.33px] items-left justify-left cursor-pointer rounded-[14px] px-6 py-2"
      whileHover={{ scale: 1.05, backgroundColor: "#f1f1f1" }}
      onClick={onClick}
    >
      <h1
        className={`font-[900] text-left text-[45px] transition-all duration-2000 ${
          !isActive ? "text-gray-400" : "text-[var(--text-color)]"
        }`}
      >
        {number}
      </h1>
      <h2
        className={`mt-1 text-left font-[900] text-[24px] transition-all duration-2000 leading-[24px] tracking-[-0.46px] ${
          !isActive ? "text-gray-400" : "text-[var(--text-color)]"
        }`}
      >
        {title}
      </h2>
      <p
        className={`mt-1 text-left transition-all duration-2000 mb-2 ${
          !isActive ? "text-gray-400" : "text-[var(--text-color)]"
        }`}
      >
        {description}
      </p>
    </motion.div>
  );
};

export default Step;