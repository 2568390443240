// ProgressSteps.js
import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import Step from "./Step";
import { motion } from "framer-motion";

const ProgressSteps = () => {
  const [activeStep, setActiveStep] = useState(1);
  const progressBarRef = useRef(null);
  const progressTween = useRef(null);

  const steps = [
    {
      number: "1",
      title: "Parlez-nous de votre vision",
      description:
        "Nous sommes à l'écoute de vos besoins pour transformer votre site web.",
    },
    {
      number: "2",
      title: "Repensons votre site ensemble",
      description:
        "Concevons un site web qui reflète votre identité tout en maximisant l'impact.",
    },
    {
      number: "3",
      title: "Repartez avec un site web",
      description:
        "Un site redéfini, moderne et optimisé pour convertir vos visiteurs en clients.",
    },
  ];

  const stepPercentages = [3, 43.33, 78.66, 100]; // Ajout de 100% pour boucler
  const totalDuration = 12; // Durée totale de 0% à 100% en secondes

  useEffect(() => {
    startProgressAnimation();

    return () => {
      if (progressTween.current) {
        progressTween.current.kill();
      }
    };
  }, []);

  const startProgressAnimation = (startPercentage = 0) => {
    if (progressTween.current) {
      progressTween.current.kill();
    }

    // Définir la largeur initiale de la barre
    progressBarRef.current.style.width = `${startPercentage}%`;

    // Calculer la durée restante en fonction du pourcentage de départ
    const remainingDuration = ((100 - startPercentage) / 100) * totalDuration;

    progressTween.current = gsap.timeline({
      onUpdate: () => {
        const progress =
          (progressBarRef.current.offsetWidth /
            progressBarRef.current.parentElement.offsetWidth) *
          100;
        updateActiveStep(progress);
      },
      onComplete: () => {
        // Démarrer le retour à 0% après avoir atteint 100%
        resetProgressBar();
      },
    });

    progressTween.current.to(progressBarRef.current, {
      width: "100%",
      duration: remainingDuration,
      ease: "linear",
    });
  };

  const resetProgressBar = () => {
    gsap.to(progressBarRef.current, {
      width: "0%",
      duration: 0.5,
      ease: "power1.inOut",
      onComplete: () => {
        // Redémarrer la progression depuis 0%
        startProgressAnimation(0);
        // Réinitialiser l'étape active à 1
        setActiveStep(1);
      },
    });
  };

  const updateActiveStep = (progressValue) => {
    for (let i = steps.length - 1; i >= 0; i--) {
      if (progressValue >= stepPercentages[i]) {
        if (activeStep !== i + 1) {
          setActiveStep(i + 1);
        }
        break;
      }
    }
  };

  const handleStepClick = (index) => {
    // Mettre à jour l'étape active immédiatement
    setActiveStep(index + 1);

    // Arrêter la timeline actuelle
    if (progressTween.current) {
      progressTween.current.kill();
    }

    // Calculer le pourcentage cible
    const targetPercentage = stepPercentages[index];

    // Aligner la barre sur le pourcentage cible
    gsap.to(progressBarRef.current, {
      width: `${targetPercentage}%`,
      duration: 0.5,
      ease: "power1.out",
      onComplete: () => {
        // Redémarrer la progression continue depuis le nouveau pourcentage
        startProgressAnimation(targetPercentage);
      },
    });
  };

  const transition = { duration: 1, ease: [0.25, 0.1, 0.25, 1] };

  return (
    <div>
      <motion.div
        initial={{ opacity: 0, y: 100, filter: "blur(20px)" }}
        animate={{ opacity: 1, y: 0, filter: "blur(0)" }}
        transition={{ ...transition, delay: 0.7 }}
      >
        <h2 className="mt-[360px] text-center text-[45px] font-[900] tracking-[-1.56px]">
          Comment ça marche ?
        </h2>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 100, filter: "blur(20px)" }}
        animate={{ opacity: 1, y: 0, filter: "blur(0)" }}
        transition={{ ...transition, delay: 0.5 }}
      >
        <div className="w-full max-w-[1000px] mx-auto mt-10 mb-16">
          <div className="h-1 rounded-full bg-gray-200">
          <div
            className="h-full bg-indigo-400 rounded-full"
            ref={progressBarRef}
            style={{ width: "0%" }}
          ></div>
          </div>
        </div>
      </motion.div>
      <div className="flex justify-center items-center gap-[90px] px-20 mt-10">
        {steps.map((step, index) => (
          <motion.div
            initial={{ opacity: 0, y: 100, filter: "blur(20px)" }}
            whileInView={{ opacity: 1, y: 0, filter: "blur(0)" }}
            viewport={{ once: true, amount: 0.8 }}
            transition={{ ...transition, delay: index * 1 }}
          >
            <Step
              key={index}
            number={step.number}
            title={step.title}
            description={step.description}
            isActive={activeStep === index + 1}
              onClick={() => handleStepClick(index)}
            />
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default ProgressSteps;